
  import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
  import * as util from "@/util";
  import mixinBundle from "@/mixins/mixinBundle";
  import CommonProgress from "@/components/common/Progress.vue";
  import BundleResidentTableMain from "@/components/Bundle/resident/table/Main.vue";

  @Component({
    components: {
      CommonProgress,
      BundleResidentTableMain,
    },
  })
  export default class Main extends Mixins(mixinBundle) {
    edit_resident() {
      this.tmp_resident_taxes = util.deep_copy(this.resident_taxes);
      this.tmp_yearend_zips = {};
      this.employees.forEach((employee) => {
        this.m.setYearendZip({ employee_id: employee.id, yearend_zip: employee.yearend_zip });
      });
      this.m.editResident();
    }

    get tmp_resident_taxes() {
      return this.m.resident_taxes;
    }
    set tmp_resident_taxes(val) {
      this.m.setResidentTaxes({ resident_taxes: val });
    }

    get tmp_yearend_zips() {
      return this.m.yearend_zips;
    }
    set tmp_yearend_zips(val) {
      this.m.setYearendZips({ yearend_zips: val });
    }
  }
