
  import { Component, Mixins } from "vue-property-decorator";
  import dialog from "@/vuex/dialog";
  import mixinBundle from "@/mixins/mixinBundle";
  import * as util from "@/util";
  import UtilPopup from "@/components/util/Popup.vue";
  import CommonBigbutton from "@/components/common/Bigbutton.vue";

  @Component({
    components: {
      UtilPopup,
      CommonBigbutton,
    },
  })
  export default class Main extends Mixins(mixinBundle) {
    excel_file = null;
    get refs(): any {
      return this.$refs;
    }

    get is_display() {
      return this.m.is_open_csv_dialog;
    }
    set is_display(val: boolean) {
      this.m.setIsOpenCsvDialog(val);
    }

    select_file(e) {
      e.preventDefault();
      const files = e.target.files;
      this.excel_file = files[0];
      if (this.excel_file) {
        let name = this.excel_file.name.split(".");
        name = name[name.length - 1];
        if (name != "csv") {
          dialog.openErrorAlertDialog({
            msg: "Excelファイルのみアップロード可能です。",
            detail: ".xlsxファイルのみ使用可能です。",
          });
          this.excel_file = null;
          this.refs.employee_ref.reset_file();
        }
      }
    }

    async update_resident_taxes_csv() {
      await this.m.updateResidentTaxesCsv({ excel: this.excel_file, year: this.year });
      this.excel_file = null;
      this.refs.employee_ref.reset_file();
    }
  }
