
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinBundle from '@/mixins/mixinBundle';
import CommonSelect from "@/components/common/Select.vue";
import BundleResidentMain from "@/components/Bundle/resident/Main.vue";

@Component({
  components: {
    CommonSelect,
    BundleResidentMain,
  }
})
export default class Main extends Mixins(mixinBundle) {
  get tab_options() {
    var header = this.header as any;
    var options = {};
    if (header.auth_employee_company != 0) options['resident'] = '住民税';
    // if (header.auth_employee_self != 0) options['base'] = '本人設定項目';
    // if (header.auth_employee_company != 0) options['payment'] = '会社設定項目';
    // if (header.auth_employee_self != 0) options['info'] = '社員情報';
    // if (header.auth_employee_self != 0) options['family'] = '家族情報';
    // if (header.auth_employee_paidhol != 0) options['holiday'] = '有給休暇';
    // if (header.auth_employee_self != 0) options['belong'] = '所属';
    // if (header.auth_employee_company != 0) options['config'] = '例外設定';
    return options;
  }

  change_tab(tab) {
    this.tab = tab;
  }
}
