
  import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
  import * as util from "@/util";
  import mixinBundle from "@/mixins/mixinBundle";
  import dialog from "@/vuex/dialog";
  import manager from "@/vuex/bundle/manager";
  import BundleResidentTableBranchselector from "@/components/Bundle/resident/table/Branchselector.vue";
  import BundleResidentPagerHead from "@/components/Bundle/resident/pager/Head.vue";
  import CommonButton from "@/components/common/Button.vue";

  @Component({
    components: {
      BundleResidentTableBranchselector,
      BundleResidentPagerHead,
      CommonButton,
    },
  })
  export default class Control extends Mixins(mixinBundle) {
    update_resident() {
      this.m.updateResident({
        branch_id: this.viewing_branch,
        year: this.year,
        employee_hash: this.employee_hash,
      });
    }
    update_yearend_zip() {
      const branch_name =
        this.viewing_branch == 0 ? "全店舗" : this.branch_hash_all[this.viewing_branch];
      this.m.updateYearendZip({
        branch_id: this.viewing_branch,
        year: this.year,
        branch_name,
      });
    }

    edit_resident() {
      const yearmonth_array = [
        `${this.year}-06`,
        `${this.year}-07`,
        `${this.year}-08`,
        `${this.year}-09`,
        `${this.year}-10`,
        `${this.year}-11`,
        `${this.year}-12`,
        `${Number(this.year) + 1}-01`,
        `${Number(this.year) + 1}-02`,
        `${Number(this.year) + 1}-03`,
        `${Number(this.year) + 1}-04`,
        `${Number(this.year) + 1}-05`,
      ];
      this.employees.forEach((employee) => {
        const resident_tax = this.resident_taxes[employee.id] ?? {};
        const resident_taxes = {};
        yearmonth_array.forEach((yearmonth) => {
          if (resident_tax[yearmonth]) {
            resident_taxes[yearmonth] = { money: resident_tax[yearmonth].money ?? 0 };
          } else {
            resident_taxes[yearmonth] = { money: 0 };
          }
        });
        this.m.setResidentTax({ employee_id: employee.id, resident_tax: resident_taxes });
        this.m.setYearendZip({
          employee_id: employee.id,
          yearend_zip: this.resident_tax_years[employee.id]?.zip ?? null,
        });
        this.m.editResident();
      });
      this.m.editResident();
    }

    get resident_tax_years() {
      return this.resident.resident_tax_years;
    }
  }
