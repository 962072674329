import { Component, Vue, Mixins } from "vue-property-decorator";
import resident from "@/vuex/bundle/resident";
import global from "@/vuex/bundle/global";
import * as util from "@/util";
import utilMixins from "@/mixins";
import manager from "@/vuex/bundle/manager";
import { BranchHeaderData } from "@/typesold/header";

@Component
export default class mixinBundle extends Mixins(utilMixins) {
  get is_set_global(): boolean {
    return global.is_set;
  }
  get is_set_resident(): boolean {
    return (
      resident.data[this.year] &&
      resident.data[this.year][this.viewing_branch] &&
      resident.data[this.year][this.viewing_branch].is_set
    );
  }

  get is_valid_global(): boolean {
    return global.is_valid;
  }
  get is_valid_resident(): boolean {
    return (
      resident.data[this.year] &&
      resident.data[this.year][this.viewing_branch] &&
      resident.data[this.year][this.viewing_branch].is_valid
    );
  }

  async get_global() {
    await global.fetch({ auth: this.s_auth });
  }
  async get_resident() {
    await resident.fetch({ year: this.year, branch_id: this.viewing_branch });
  }

  get global() {
    if (!this.is_set_global || !this.is_valid_global) this.get_global();
    if (this.is_set_global) return global.data;
  }
  get resident() {
    if (!this.is_set_resident || !this.is_valid_resident) this.get_resident();
    if (this.is_set_resident) return resident.data[this.year][this.viewing_branch];
  }

  get viewing_branch() {
    var branch_id = this.query.viewing_branch;
    if (branch_id === undefined) {
      branch_id = Number(util.getLocalStorage("branch_id"));
      this.query = { viewing_branch: branch_id };
    }
    return branch_id;
  }
  set viewing_branch(val) {
    this.page = 1;
    this.query = { viewing_branch: val };
  }
  get year() {
    if (this.tab == "resident") {
      var year = this.query.bundle_resident_year;
      if (!year) {
        const year_obj = new Date();
        year = year_obj.getFullYear();
        this.query = { bundle_resident_year: year };
      }
      return year;
    }
  }
  set year(val) {
    if (this.tab == "resident") {
      if (val != this.year) {
        this.query_push = { bundle_resident_year: val };
      }
    }
  }
  get is_open() {
    if (this.tab == "resident") {
      return this.query.is_open_bundle_resident;
    }
  }
  set is_open(val) {
    if (this.tab == "resident") {
      if (val == "" || val == null) {
        this.query = { is_open_bundle_resident: undefined };
      } else {
        this.query = { is_open_bundle_resident: val };
      }
    }
  }

  get tab() {
    var tab = this.query.bundle_tab;
    if (tab === undefined) {
      tab = "resident";
      this.query = { bundle_tab: tab };
    }
    return tab;
  }
  set tab(val) {
    this.query_push = { bundle_tab: val };
  }

  get m() {
    return manager;
  }

  get resident_taxes() {
    if (this.tab == "resident") {
      return this.resident.resident_taxes;
    }
  }
  get employees() {
    if (this.tab == "resident") {
      return this.resident.employees;
    }
  }
  get employee_hash() {
    if (this.tab == "resident") {
      return this.resident.employee_hash;
    }
  }
  get branch_hash_all() {
    return this.global.branch_hash_all;
  }

  get is_latest_year_resident() {
    if (this.tab == "resident") {
      var year = util.date2year(util.today());
      return this.year >= year;
    }
  }

  get search_key() {
    var search_key = this.query.bundle_search_key;
    if (search_key === undefined || search_key == "") {
      this.query = { bundle_search_key: undefined };
      return null;
    } else {
      return search_key;
    }
  }
  set search_key(val) {
    this.page = 1;
    if (val == "" || val == null) {
      this.query = { bundle_search_key: undefined };
    } else {
      this.query = { bundle_search_key: val };
    }
  }

  get sort_code() {
    return this.query.bundle_sort_code;
  }
  set sort_code(val) {
    if (val == "" || val == null) {
      this.query = { bundle_sort_code: undefined };
    } else {
      this.query = { bundle_sort_code: val };
    }
  }
  get sort_name() {
    return this.query.bundle_sort_name;
  }
  set sort_name(val) {
    if (val == "" || val == null) {
      this.query = { bundle_sort_name: undefined };
    } else {
      this.query = { bundle_sort_name: val };
    }
  }
  get sort_hiring_type() {
    return this.query.bundle_sort_hiring_type;
  }
  set sort_hiring_type(val) {
    if (val == "" || val == null) {
      this.query = { bundle_sort_hiring_type: undefined };
    } else {
      this.query = { bundle_sort_hiring_type: val };
    }
  }
  get sort_branch_id() {
    return this.query.bundle_sort_branch_id;
  }
  set sort_branch_id(val) {
    if (val == "" || val == null) {
      this.query = { bundle_sort_branch_id: undefined };
    } else {
      this.query = { bundle_sort_branch_id: val };
    }
  }
  get sort_yearend_zip() {
    return this.query.bundle_sort_yearend_zip;
  }
  set sort_yearend_zip(val) {
    if (val == "" || val == null) {
      this.query = { bundle_sort_yearend_zip: undefined };
    } else {
      this.query = { bundle_sort_yearend_zip: val };
    }
  }

  get page() {
    var page = this.query.bundle_page;
    if (page === undefined || page == "") {
      this.query = { bundle_page: 1 };
      page = 1;
    }
    return page;
  }
  set page(val) {
    if (val == "" || val == null) {
      this.query = { bundle_page: undefined };
    } else {
      this.query = { bundle_page: val };
    }
  }

  get searched_sorted_employees() {
    var employees = util.deep_copy(this.employees);
    employees = this.search_employees(employees);
    employees = this.sort_employees(employees);
    return employees;
  }
  get managed_employees() {
    var employees = util.deep_copy(this.searched_sorted_employees);
    employees = this.page_employees(employees);
    return employees;
  }
  search_employees(employees) {
    if (this.search_key === null) {
      return employees;
    } else {
      const search_keys = this.search_key.split(/\s+/).filter((str) => str != "");
      var _this = this;
      return employees.filter((employee) => {
        var flag = 0;
        search_keys.forEach((key) => {
          if (
            employee.name.toLowerCase().indexOf(key.toLowerCase()) != -1 ||
            util.search_kana(employee.kana, key) ||
            (employee.code && employee.code.toLowerCase().indexOf(key.toLowerCase()) != -1) ||
            (employee.yearend_address &&
              employee.yearend_address.toLowerCase().indexOf(key.toLowerCase()) != -1)
          ) {
            flag = 1;
            return;
          }
        });
        return flag;
      });
    }
  }
  sort_employees(employees) {
    if (this.sort_code) employees.sort((x, y) => util.sort_idx(x.code, y.code, this.sort_code));
    else if (this.sort_name)
      employees.sort((x, y) => util.sort_idx(x.kana, y.kana, this.sort_name));
    else if (this.sort_hiring_type)
      employees.sort((x, y) => util.sort_idx(x.hiring_type, y.hiring_type, this.sort_hiring_type));
    else if (this.sort_branch_id)
      employees.sort((x, y) => util.sort_idx(x.branch_id, y.branch_id, this.sort_branch_id));
    else if (this.sort_yearend_zip)
      employees.sort((x, y) =>
        util.sort_idx(
          this.resident.resident_tax_years[x.id] ? this.resident.resident_tax_years[x.id].zip : 0,
          this.resident.resident_tax_years[y.id] ? this.resident.resident_tax_years[y.id].zip : 0,
          this.sort_yearend_zip,
        ),
      );
    else
      employees.sort((x, y) =>
        util.sort_idx(
          this.resident.resident_tax_years[x.id] ? this.resident.resident_tax_years[x.id].zip : 0,
          this.resident.resident_tax_years[y.id] ? this.resident.resident_tax_years[y.id].zip : 0,
          "asc",
        ),
      );
    return employees;
  }
  page_employees(employees) {
    return employees.slice(this.disp_num * (this.page - 1), this.disp_num * this.page);
  }

  sort_click(sort_key) {
    this.page = 1;
    sort_key = "sort_" + sort_key;
    var sort_mode = undefined;
    if (this[sort_key] === undefined) {
      sort_mode = "asc";
    } else if (this[sort_key] == "asc") {
      sort_mode = "desc";
    } else if (this[sort_key] == "desc") {
      sort_mode = "asc";
    }
    this.set_sort(sort_key, sort_mode);
  }
  set_sort(sort_key, sort_mode) {
    console.log(sort_key, sort_mode);
    this.query = {
      bundle_sort_code: sort_key == "sort_code" ? sort_mode : undefined,
      bundle_sort_name: sort_key == "sort_name" ? sort_mode : undefined,
      bundle_sort_hiring_type: sort_key == "sort_hiring_type" ? sort_mode : undefined,
      bundle_sort_branch_id: sort_key == "sort_branch_id" ? sort_mode : undefined,
      bundle_sort_yearend_zip: sort_key == "sort_yearend_zip" ? sort_mode : undefined,
    };
  }

  get disp_num() {
    return manager.disp_num;
  }
  set disp_num(val) {
    this.page = 1;
    util.setLocalStorage("bundle_disp_num", val);
    manager.setDispNum(val);
  }
}
