
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinBundle from '@/mixins/mixinBundle';
import global from "@/vuex/bundle/global";
import dialog from "@/vuex/dialog";
import manager from "@/vuex/bundle/manager";
import BundleResidentTableInputrow from "@/components/Bundle/resident/table/Inputrow.vue";
import BundleResidentTableRow from "@/components/Bundle/resident/table/Row.vue";

@Component({
  components: {
    BundleResidentTableInputrow,
    BundleResidentTableRow,
  }
})
export default class Table extends Mixins(mixinBundle) {
  get table_column() {
    if (this.is_open) {
      return 13;
    } else {
      return 1;
    }
  }

  get yearmonth_array() {
    return [
      this.year+'年6月',
      this.year+'年7月',
      this.year+'年8月',
      this.year+'年9月',
      this.year+'年10月',
      this.year+'年11月',
      this.year+'年12月',
      (Number(this.year) + 1)+'年1月',
      (Number(this.year) + 1)+'年2月',
      (Number(this.year) + 1)+'年3月',
      (Number(this.year) + 1)+'年4月',
      (Number(this.year) + 1)+'年5月',
    ];
  }


  get resident_tax_sum() {
    var sum = {0: 0, 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, 7: 0, 8: 0, 9: 0, 10: 0, 11: 0, 'all': 0,}
    this.searched_sorted_employees.forEach(employee => {
      if (this.resident_taxes[employee.id]) {
        sum[0] += this.resident_taxes[employee.id][this.year+'-06']?.money ?? 0;
        sum[1] += this.resident_taxes[employee.id][this.year+'-07']?.money ?? 0;
        sum[2] += this.resident_taxes[employee.id][this.year+'-08']?.money ?? 0;
        sum[3] += this.resident_taxes[employee.id][this.year+'-09']?.money ?? 0;
        sum[4] += this.resident_taxes[employee.id][this.year+'-10']?.money ?? 0;
        sum[5] += this.resident_taxes[employee.id][this.year+'-11']?.money ?? 0;
        sum[6] += this.resident_taxes[employee.id][this.year+'-12']?.money ?? 0;
        sum[7] += this.resident_taxes[employee.id][(Number(this.year) + 1)+'-01']?.money ?? 0;
        sum[8] += this.resident_taxes[employee.id][(Number(this.year) + 1)+'-02']?.money ?? 0;
        sum[9] += this.resident_taxes[employee.id][(Number(this.year) + 1)+'-03']?.money ?? 0;
        sum[10] += this.resident_taxes[employee.id][(Number(this.year) + 1)+'-04']?.money ?? 0;
        sum[11] += this.resident_taxes[employee.id][(Number(this.year) + 1)+'-05']?.money ?? 0;
      }
    });
    sum['all'] = sum[0] + sum[1] + sum[2] + sum[3] + sum[4] + sum[5] + sum[6] + sum[7] + sum[8] + sum[9] + sum[10] + sum[11];
    return sum;
  }
}
