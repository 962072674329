
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinBundle from '@/mixins/mixinBundle';
import global from "@/vuex/attendance/global";
import dialog from "@/vuex/dialog";
import manager from "@/vuex/attendance/manager";
import CommonText from "@/components/common/Text.vue";
import CommonZip from "@/components/common/Zip.vue";

@Component({
  components: {
    CommonText,
    CommonZip,
  }
})
export default class Inputrow extends Mixins(mixinBundle) {
  @Prop() employee;
  @Prop() resident_tax_year;

  get tmp_yearend_zip() {
    return this.m.yearend_zips[this.employee.id];
  }
  set tmp_yearend_zip(val) {
    Vue.set(this.m.yearend_zips, this.employee.id, val);
  }

  get yearmonth_array() {
    return [
      this.year+'-06',
      this.year+'-07',
      this.year+'-08',
      this.year+'-09',
      this.year+'-10',
      this.year+'-11',
      this.year+'-12',
      (Number(this.year) + 1)+'-01',
      (Number(this.year) + 1)+'-02',
      (Number(this.year) + 1)+'-03',
      (Number(this.year) + 1)+'-04',
      (Number(this.year) + 1)+'-05',
    ];
  }

  input_resident_tax(val, i) {
    Vue.set(this.tmp_resident_tax, this.yearmonth_array[i], {money: val});
  }

  calc_resident_tax_sum(val) {
    const tmp = Math.floor(val / 1200) * 100;
    var first = val - (tmp * 11);
    var other = tmp;

    Vue.set(this.tmp_resident_tax, this.yearmonth_array[0], {money: first});
    for (var i = 1; i < 12; i++) {
      Vue.set(this.tmp_resident_tax, this.yearmonth_array[i], {money: other});
    }
  }

  get tmp_resident_tax() {
    return this.m.resident_taxes[this.employee.id];
  }
  set tmp_resident_tax(val) {
    this.m.setResidentTax({employee_id: this.employee.id, resident_tax: val});
  }
  get resident_tax_sum() {
    var sum = 0;
    this.yearmonth_array.forEach(yearmonth => {
      if (this.tmp_resident_tax && this.tmp_resident_tax[yearmonth]) {
        sum += Number(this.tmp_resident_tax[yearmonth].money);
      }
    });
    return sum;
  }
}
