
  import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
  import * as util from "@/util";
  import mixinBundle from "@/mixins/mixinBundle";
  import global from "@/vuex/bundle/global";
  import dialog from "@/vuex/dialog";
  import manager from "@/vuex/bundle/manager";
  import CommonProgress from "@/components/common/Progress.vue";
  import BundleResidentTableControl from "@/components/Bundle/resident/table/Control.vue";
  import BundleResidentTableTable from "@/components/Bundle/resident/table/Table.vue";
  import BundleResidentPagerFoot from "@/components/Bundle/resident/pager/Foot.vue";
  import BundleResidentDialogImportCsv from "@/components/Bundle/resident/dialog/ImportCsv.vue";

  @Component({
    components: {
      CommonProgress,
      BundleResidentTableControl,
      BundleResidentTableTable,
      BundleResidentPagerFoot,
      BundleResidentDialogImportCsv,
    },
  })
  export default class Main extends Mixins(mixinBundle) {}
