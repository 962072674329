
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import utilMixins from '@/mixins';
import dialog from "@/vuex/dialog";
import mixinBundle from '@/mixins/mixinBundle';
import BundleMain from "@/components/Bundle/Main.vue";

@Component({
  components: {
    BundleMain,
  }
})
export default class Payment extends Mixins(mixinBundle) {
  get title() {
    if (this.tab == 'resident') {
      return '住民税改定';
    }
  }
}
