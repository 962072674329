
import { Component, Vue, Emit, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import utilMixins from '@/mixins';
import mixinBundle from '@/mixins/mixinBundle';
import global from "@/vuex/bundle/global";
import CommonSelect from "@/components/common/Select.vue";

@Component({
  components: {
    CommonSelect,
  }
})
export default class Branchselector extends Mixins(mixinBundle) {
  get branch_hash() {
    return this.global.branch_hash;
  }
}
