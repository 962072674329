
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinBundle from '@/mixins/mixinBundle';
import global from "@/vuex/bundle/global";
import dialog from "@/vuex/dialog";
import manager from "@/vuex/bundle/manager";

@Component({
  components: {
  }
})
export default class Row extends Mixins(mixinBundle) {
  @Prop() employee;
  @Prop() resident_tax_year;

  get yearend_zip() {
    if (this.resident_tax_year == null) {
      return '未登録';
    } else {
      if (this.resident_tax_year.siku === null) {
        return '不明';
      } else {
        return this.resident_tax_year.siku;
      }
    }
  }

  get resident_tax() {
    if (this.resident_taxes[this.employee.id]) {
      return [
        this.resident_taxes[this.employee.id][this.year+'-06'] ?? {money: 0},
        this.resident_taxes[this.employee.id][this.year+'-07'] ?? {money: 0},
        this.resident_taxes[this.employee.id][this.year+'-08'] ?? {money: 0},
        this.resident_taxes[this.employee.id][this.year+'-09'] ?? {money: 0},
        this.resident_taxes[this.employee.id][this.year+'-10'] ?? {money: 0},
        this.resident_taxes[this.employee.id][this.year+'-11'] ?? {money: 0},
        this.resident_taxes[this.employee.id][this.year+'-12'] ?? {money: 0},
        this.resident_taxes[this.employee.id][(Number(this.year) + 1)+'-01'] ?? {money: 0},
        this.resident_taxes[this.employee.id][(Number(this.year) + 1)+'-02'] ?? {money: 0},
        this.resident_taxes[this.employee.id][(Number(this.year) + 1)+'-03'] ?? {money: 0},
        this.resident_taxes[this.employee.id][(Number(this.year) + 1)+'-04'] ?? {money: 0},
        this.resident_taxes[this.employee.id][(Number(this.year) + 1)+'-05'] ?? {money: 0},
      ];
    } else {
      return [
        {money: 0},
        {money: 0},
        {money: 0},
        {money: 0},
        {money: 0},
        {money: 0},
        {money: 0},
        {money: 0},
        {money: 0},
        {money: 0},
        {money: 0},
        {money: 0},
      ];
    }
  }

  get resident_tax_sum() {
    var sum = 0;
    this.resident_tax.forEach(tax => {
      sum += tax.money;
    });
    return sum;
  }
}
